import Vue from 'vue';
import App from './App.vue';
import router from '~/router';
import store from '~/store';
import '../public/SplitText.min';
import '~/directives/focus';
import '~/directives/clickOutside';
Vue.config.productionTip = false;

Number.prototype.roundDownToNearest = function (d) {
    return "" + Math.floor(this / d) * d;
};

Vue.filter('percent', function (value) {
    return (value * 100).toFixed(0) + '%';
});

Vue.filter('age' , function (value) {
    let floor = Math.floor(value);
    let months = Math.floor(value*12 -floor*12);

    return months > 0? `${floor} and ${months} months` : floor;
});


Vue.filter('roundDown', function (value, nearest) {
    let n = value, c = nearest == undefined? 0 : nearest,
        d = ".",
        t = ",", 
        i = String(parseInt(n = Math.abs(+n || 0).roundDownToNearest(c))),    
        j = i.length > 3 ? i.length % 3 : 0;

    return (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t);
});

Vue.filter('nameFormat', function (value) {
    if (typeof value !== 'string') return '';
    return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
